.booking-form-container {
    margin-bottom: 40px;
    display: flex;
    overflow-x: hidden;
    justify-content: center;
}


.booking-form-paper {
    border-radius: 12px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.datuma {
    padding-left: 10px;
}

.form-title {
    font-weight: 700;
    margin-inline: auto;
    color: #2c3e50;

}

.form-description1 {
    padding-top: 10px;
    padding-bottom: 0px;
    color: #555;
    line-height: 1.8;
}



.form-divider {
    margin: 40px 0;
}

.cost-levels-title {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
    color: #2c3e50;
}

.cost-levels {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.cost-level-card {
    background-color: #e0f7fa;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cost-level-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.radio-group-title {
    margin-bottom: 10px;
    font-size: 18px;
    color: #2c3e50;
}

.submit-button {
    background-color: #007bff;
    color: white;
    padding: 16px 0;
    border-radius: 8px;
    font-size: 18px;
    transition: background-color 0.3s ease;
    font-weight: 600;
}

.submit-button:hover {
    background-color: #0056b3;
}

.submit-button:disabled {
    background-color: #cccccc;
    color: #666666;
}


.trip-type-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

/* Grayscale på icke-valda kort */
.trip-type-card .card-media {
    filter: grayscale(100%);
    /* Gråskala på alla kort som standard */
    transition: transform 0.3s ease, filter 0.3s ease;
}

/* Ingen grayscale på valda kort */
.trip-type-card.selected .card-media {
    filter: grayscale(0%);
    /* Färgbild för valda kort */
}

/* Hover-effekt */
.trip-type-card:hover .card-media {
    transform: scale(1.05);
    /* Liten zoom-effekt vid hover */
}

.card-media {
    transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
    /* Smooth zoom, shadow och filter-effekt */
}

.card-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    text-align: center;
    padding: 10px;
}

@keyframes zoomInOut {
    0% {
        transform: scale(1) translateY(0);
    }

    97% {
        transform: scale(1.3) translateY(35px);
        /* Zoomar in och flyttar uppåt på y-axeln */
    }
    100% {
        transform: scale(1) translateY(0);
        /* Zoomar in och flyttar uppåt på y-axeln */
    }
}